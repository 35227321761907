import { captureRemixErrorBoundaryError } from "@sentry/remix";
import interStylesHref from "@fontsource/inter/index.css?url";
import { LinksFunction, MetaFunction } from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import { getInitColorSchemeScript, CssBaseline, CssVarsProvider } from "@mui/joy";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: interStylesHref },
  { rel: "icon", type: "image/ico", href: "/favicon.ico" },
];

export const meta: MetaFunction = () => [
  {
    property: "description",
    content: "Split bills and track who paid back what",
  },
  { property: "og:title", content: "Billy - Split with friends" },
  {
    property: "og:description",
    content: "Split bills and track who paid back what",
  },
  { property: "og:url", content: "https://billy-split.it" },
  { property: "og:image", content: "https://billy-split.it/favicon.ico" },
  { title: "Billy - Scan bills and split with friends" },
];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <CssVarsProvider>
      <CssBaseline />
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <Meta />
          <Links />
        </head>
        <body>
          {getInitColorSchemeScript()}
          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    </CssVarsProvider>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}
